<template>
  <div id="project-features">
    <div class="column">
      <FeaturesListAndMapFilters
        :show-map="showMap"
        :features-count="featuresCountDisplay"
        :pagination="pagination"
        :all-selected="allSelected"
        :edit-attributes-feature-type="editAttributesFeatureType"
        @set-filter="setFilters"
        @reset-pagination="resetPagination"
        @fetch-features="fetchPagedFeatures"
        @show-map="setShowMap"
        @edit-status="modifyStatus"
        @toggle-delete-modal="toggleDeleteModal"
      />

      <div class="loader-container">
        <div
          :class="['ui tab active map-container', { 'visible': showMap }]"
          data-tab="map"
        >
          <div
            id="map"
            ref="map"
          >
            <SidebarLayers
              v-if="basemaps && map"
              ref="sidebar"
            />
            <Geolocation />
            <Geocoder />
          </div>
          <div 
            id="popup" 
            class="ol-popup"
          >
            <a 
              id="popup-closer" 
              href="#" 
              class="ol-popup-closer"
            />
            <div 
              id="popup-content"
            />
          </div>
        </div>
        <FeatureListTable
          v-show="!showMap"
          :paginated-features="paginatedFeatures"
          :page-numbers="pageNumbers"
          :all-selected="allSelected"
          :checked-features.sync="checkedFeatures"
          :features-count="featuresCount"
          :pagination="pagination"
          :sort="sort"
          :edit-attributes-feature-type.sync="editAttributesFeatureType"
          :queryparams="queryparams"
          @update:page="handlePageChange"
          @update:sort="handleSortChange"
          @update:allSelected="handleAllSelectedChange"
        />
        <Transition name="fadeIn">
          <div
            v-if="loading"
            class="ui inverted dimmer active"
          >
            <div class="ui text loader">
              Récupération des signalements en cours...
            </div>
          </div>
        </Transition>
      </div>

    
      <!-- MODAL ALL DELETE FEATURE TYPE -->
      <div
        v-if="isDeleteModalOpen"
        class="ui dimmer modals page transition visible active"
        style="display: flex !important"
      >
        <div
          :class="[
            'ui mini modal',
            { 'active visible': isDeleteModalOpen },
          ]"
        >
          <i
            class="close icon"
            aria-hidden="true"
            @click="isDeleteModalOpen = false"
          />
          <div class="ui icon header">
            <i
              class="trash alternate icon"
              aria-hidden="true"
            />
            Êtes-vous sûr de vouloir effacer
            <span v-if="checkedFeatures.length === 1"> un signalement&nbsp;?</span>
            <span v-else-if="checkedFeatures.length > 1">ces {{ checkedFeatures.length }} signalements&nbsp;?</span>
            <span v-else>tous les signalements sélectionnés&nbsp;?<br>
              <small>Seuls ceux que vous êtes autorisé à supprimer seront réellement effacés.</small>
            </span>
          </div>
          <div class="actions">
            <button
              type="button"
              class="ui red compact fluid button"
              @click="deleteAllFeatureSelection"
            >
              Confirmer la suppression
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex';
import mapService from '@/services/map-service';
import Geocoder from '@/components/Map/Geocoder';
import featureAPI from '@/services/feature-api';

import FeaturesListAndMapFilters from '@/components/Project/FeaturesListAndMap/FeaturesListAndMapFilters';
import FeatureListTable from '@/components/Project/FeaturesListAndMap/FeatureListTable';
import SidebarLayers from '@/components/Map/SidebarLayers';
import Geolocation from '@/components/Map/Geolocation';

const initialPagination = {
  currentPage: 1,
  pagesize: 15,
  start: 0,
  end: 15,
};

export default {
  name: 'FeaturesListAndMap',

  components: {
    FeaturesListAndMapFilters,
    SidebarLayers,
    Geocoder,
    Geolocation,
    FeatureListTable,
  },

  data() {
    return {
      allSelected: false,
      editAttributesFeatureType: null,
      currentLayer: null,
      featuresCount: 0,
      featuresWithGeomCount:0,
      form: {
        type: [],
        status: [],
        title: null,
      },
      isDeleteModalOpen: false,
      loading: false,
      lat: null,
      lng: null,
      map: null,
      paginatedFeatures: [],
      pagination: { ...initialPagination },
      projectSlug: this.$route.params.slug,
      queryparams: {},
      showMap: true,
      sort: {
        column: 'updated_on',
        ascending: true,
      },
      zoom: null,
    };
  },

  computed: {
    ...mapState([
      'isOnline'
    ]),
    ...mapState('projects', [
      'project',
    ]),
    ...mapState('feature', [
      'checkedFeatures',
      'clickedFeatures',
    ]),
    ...mapState('feature-type', [
      'feature_types',
    ]),
    ...mapState('map', [
      'basemaps',
    ]),

    API_BASE_URL() {
      return this.$store.state.configuration.VUE_APP_DJANGO_API_BASE;
    },

    pageNumbers() {
      return this.createPagesArray(this.featuresCount, this.pagination.pagesize);
    },

    featuresCountDisplay() {
      return this.showMap ? this.featuresWithGeomCount : this.featuresCount;
    }
  },


  watch: {
    isOnline(newValue, oldValue) {
      if (newValue != oldValue && !newValue) {
        this.DISPLAY_MESSAGE({
          comment: 'Les signalements du projet non mis en cache ne sont pas accessibles en mode déconnecté',
        });
      }
    },
  },

  mounted() {
    this.UPDATE_CHECKED_FEATURES([]); // empty for when turning back from edit attributes page
    if (!this.project) {
      // Chargements des features et infos projet en cas d'arrivée directe sur la page ou de refresh
      Promise.all([
        this.$store.dispatch('projects/GET_PROJECT', this.projectSlug),
        this.$store.dispatch('projects/GET_PROJECT_INFO', this.projectSlug)
      ]).then(()=> this.initPage());
    } else {
      this.initPage();
    }
  },

  destroyed() {
    //* allow user to change page if ever stuck on loader
    this.loading = false;
  },

  methods: {
    ...mapMutations([
      'DISPLAY_MESSAGE',
    ]),
    ...mapActions('feature', [
      'DELETE_FEATURE',
    ]),

    ...mapMutations('feature', [
      'UPDATE_CHECKED_FEATURES'
    ]),

    setShowMap(newValue) {
      this.showMap = newValue;
      // expanded sidebar is visible under the list, even when the map is closed (position:absolute), solved by closing it when switching to list
      if (newValue === false && this.$refs.sidebar) this.$refs.sidebar.toggleSidebar(false);
    },
    resetPagination() {
      this.pagination = { ...initialPagination };
    },
    
    /**
     * Updates the filters based on the provided key-value pair.
     *
     * @param {Object} e - The key-value pair representing the filter to update.
     */
    setFilters(e) {
      const filter = Object.keys(e)[0];
      let value = Object.values(e)[0];
      if (value && Array.isArray(value)) {
        value = value.map(el => el.value);
      }
      this.form[filter] = value;
    },

    toggleDeleteModal() {
      this.isDeleteModalOpen = !this.isDeleteModalOpen;
    },

    /**
     * Modifie le statut des objets sélectionnés.
     *
     * Cette méthode prend en charge deux cas :
     * 1. Si tous les objets sont sélectionnés (`allSelected`), une requête unique en mode "bulk update" est envoyée
     *    au backend pour modifier le statut de tous les objets correspondant aux critères.
     * 2. Si des objets spécifiques sont sélectionnés (`checkedFeatures`), ils sont traités un par un de manière
     *    récursive. Chaque objet modifié est retiré de la liste des objets sélectionnés.
     *
     * En cas d'erreur (réseau ou backend), un message d'erreur est affiché, et les données sont rafraîchies.
     * Si tous les objets sont modifiés avec succès, un message de confirmation est affiché.
     *
     * @param {string} newStatus - Le nouveau statut à appliquer aux objets sélectionnés.
     * @returns {Promise<void>} - Une promesse qui se résout lorsque tous les objets ont été traités.
     */
    async modifyStatus(newStatus) {
      if (this.allSelected) {
        // Cas : Modification en masse de tous les objets
        try {
          // Update additional query parameters based on the current filter states.
          this.updateQueryParams();
          const queryString = new URLSearchParams(this.queryparams).toString();
          const response = await featureAPI.projectFeatureBulkUpdateStatus(this.projectSlug, queryString, newStatus);

          if (response && response.data) {
            // Affiche un message basé sur la réponse du backend
            this.DISPLAY_MESSAGE({
              comment: response.data.message,
              level: response.data.level,
            });
          }
        } catch (error) {
          // Gère les erreurs de type Axios (400, 500, etc.)
          if (error.response && error.response.data) {
            this.DISPLAY_MESSAGE({
              comment: error.response.data.error || 'Une erreur est survenue.',
              level: 'negative',
            });
          } else {
            // Gère les erreurs réseau ou autres
            this.DISPLAY_MESSAGE({
              comment: 'Impossible de communiquer avec le serveur.',
              level: 'negative',
            });
          }
        }
        // Rafraîchit les données après un traitement global
        this.resetPagination();
        this.fetchPagedFeatures();
      } else if (this.checkedFeatures.length > 0) {
        // Cas : Traitement des objets un par un
        const feature_id = this.checkedFeatures[0]; // Récupère l'ID du premier objet sélectionné
        const feature = this.clickedFeatures.find((el) => el.feature_id === feature_id); // Trouve l'objet complet

        if (feature) {
          // Envoie une requête pour modifier le statut d'un objet spécifique
          const response = await featureAPI.updateFeature({
            feature_id,
            feature_type__slug: feature.feature_type,
            project__slug: this.projectSlug,
            newStatus,
          });

          if (response && response.data && response.status === 200) {
            // Supprime l'objet traité de la liste des objets sélectionnés
            const newCheckedFeatures = [...this.checkedFeatures];
            newCheckedFeatures.splice(this.checkedFeatures.indexOf(response.data.id), 1);
            this.UPDATE_CHECKED_FEATURES(newCheckedFeatures);
            // Rappel récursif pour traiter l'objet suivant
            this.modifyStatus(newStatus);
          } else {
            // Affiche un message d'erreur si la modification échoue
            this.DISPLAY_MESSAGE({
              comment: `Le signalement ${feature.title} n'a pas pu être modifié.`,
              level: 'negative',
            });
            // Rafraîchit les données en cas d'erreur
            this.fetchPagedFeatures();
          }
        }
      } else {
        // Cas : Tous les objets ont été traités après le traitement récursif
        this.fetchPagedFeatures(); // Rafraîchit les données pour afficher les mises à jour
        this.DISPLAY_MESSAGE({
          comment: 'Tous les signalements ont été modifiés avec succès.',
          level: 'positive',
        });
      }
    },

    /**
     * Supprime tous les objets sélectionnés.
     *
     * Cette méthode prend en charge deux cas :
     * 1. Si tous les objets sont sélectionnés (`allSelected`), une requête unique en mode "bulk delete" est envoyée
     *    au backend pour supprimer tous les objets correspondant aux critères. La liste des résultats est ensuite rafraichie.
     * 2. Si des objets spécifiques sont sélectionnés (`checkedFeatures`), ils sont traités un par un de manière
     *    récursive. Cette méthode utilise `Promise.all` pour envoyer les requêtes de suppression en parallèle
     *    pour tous les objets dans la liste `checkedFeatures`. Après suppression, elle met à jour la pagination
     *    et rafraîchit les objets affichés pour refléter les changements.
     *
     * En cas d'erreur (réseau ou backend), un message d'erreur est affiché, et les données sont rafraîchies.
     * Si tous les objets sont supprimé avec succès, un message de confirmation est affiché.
     *
     * @returns {Promise<void>} - Une promesse qui se résout lorsque tous les objets ont été traités.
     */
    async deleteAllFeatureSelection() {
      if (this.allSelected) {
        // Cas : Suppression en masse de tous les objets
        try {
          // Update additional query parameters based on the current filter states.
          this.updateQueryParams();
          const queryString = new URLSearchParams(this.queryparams).toString();
          const response = await featureAPI.projectFeatureBulkDelete(this.projectSlug, queryString);

          if (response && response.data) {
            // Affiche un message basé sur la réponse du backend
            this.DISPLAY_MESSAGE({
              comment: response.data.message,
              level: response.data.level,
            });
          }
        } catch (error) {
          // Gère les erreurs de type Axios (400, 500, etc.)
          if (error.response && error.response.data) {
            this.DISPLAY_MESSAGE({
              comment: error.response.data.error || 'Une erreur est survenue.',
              level: 'negative',
            });
          } else {
            // Gère les erreurs réseau ou autres
            this.DISPLAY_MESSAGE({
              comment: 'Impossible de communiquer avec le serveur.',
              level: 'negative',
            });
          }
        }
        // Rafraîchit les données après un traitement global
        this.resetPagination();
        this.fetchPagedFeatures();
      } else {
        // Sauvegarde le nombre total d'objets
        const initialFeaturesCount = this.featuresCount;
        // Sauvegarde la page actuelle
        const initialCurrentPage = this.pagination.currentPage;
        // Crée une liste de promesses pour supprimer chaque objet sélectionné
        const promises = this.checkedFeatures.map((feature_id) =>
          this.DELETE_FEATURE({ feature_id, noFeatureType: true })
        );
        // Exécute toutes les suppressions en parallèle
        Promise.all(promises)
          .then((response) => {
            // Compte le nombre d'objets supprimés avec succès
            const deletedFeaturesCount = response.reduce(
              (acc, curr) => (curr.status === 204 ? acc + 1 : acc),
              0
            );
            // Calcule le nouveau total d'objets
            const newFeaturesCount = initialFeaturesCount - deletedFeaturesCount;
            // Recalcule les pages
            const newPagesArray = this.createPagesArray(newFeaturesCount, this.pagination.pagesize);
            // Dernière page valide
            const newLastPageNum = newPagesArray[newPagesArray.length - 1];
            // Réinitialise la sélection
            this.$store.commit('feature/UPDATE_CHECKED_FEATURES', []);

            if (initialCurrentPage > newLastPageNum) {
              // Navigue à la dernière page valide si la page actuelle n'existe plus
              this.toPage(newLastPageNum);
            } else {
              // Rafraîchit les objets affichés
              this.fetchPagedFeatures();
            }
          })
          // Gère les erreurs éventuelles
          .catch((err) => console.error(err));
      }
      // Ferme la modale de confirmation de suppression
      this.toggleDeleteModal();
    },

    onFilterChange() {
      if (mapService.getMap() && mapService.mvtLayer) {
        mapService.mvtLayer.changed();
      }
    },

    initPage() {
      this.sort = {
        column: this.project.feature_browsing_default_sort.replace('-', ''),
        ascending: this.project.feature_browsing_default_sort.includes('-')
      };
      this.initMap();
    },

    initMap() {
      this.zoom = this.$route.query.zoom || '';
      this.lat = this.$route.query.lat || '';
      this.lng = this.$route.query.lng || '';

      var mapDefaultViewCenter =
        this.$store.state.configuration.DEFAULT_MAP_VIEW.center;
      var mapDefaultViewZoom =
        this.$store.state.configuration.DEFAULT_MAP_VIEW.zoom;

      this.map = mapService.createMap(this.$refs.map, {
        zoom: this.zoom,
        lat: this.lat,
        lng: this.lng,
        mapDefaultViewCenter,
        mapDefaultViewZoom,
        maxZoom: this.project.map_max_zoom_level,
        interactions : { doubleClickZoom :false, mouseWheelZoom:true, dragPan:true },
        fullScreenControl: true,
        geolocationControl: true,
      });

      this.$nextTick(() => {
        const mvtUrl = `${this.API_BASE_URL}features.mvt`;
        mapService.addVectorTileLayer({
          url: mvtUrl,
          project_slug: this.projectSlug,
          featureTypes: this.feature_types,
          formFilters: this.form,
          queryParams: this.queryparams,
        });
      });

      this.fetchPagedFeatures();
    },

    fetchBboxNfit(queryString) {
      featureAPI
        .getFeaturesBbox(this.projectSlug, queryString)
        .then((bbox) => {
          if (bbox) {
            mapService.fitBounds(bbox);
          }
        });
    },

    //* Paginated Features for table *//
    getFeatureTypeSlug(title) {
      const featureType = this.feature_types.find((el) => el.title === title);
      return featureType ? featureType.slug : null;
    },

    getAvalaibleField(orderField) {
      let result = orderField;
      if (orderField === 'display_creator') {
        result = 'creator';
      } else if (orderField === 'display_last_editor') {
        result = 'last_editor';
      }
      return result;
    },

    /**
     * Updates the query parameters based on the current state of the pagination and form filters.
     * This function sets various parameters like offset, feature_type_slug, status__value, title,
     * and ordering to be used in an API request and to filter hidden features on mvt tiles.
     */
    updateQueryParams() {
      // empty queryparams to remove params when removed from the form
      this.queryparams = {};
      // Update the 'offset' parameter based on the current pagination start value.
      this.queryparams['offset'] = this.pagination.start;
      // Set 'feature_type_slug' if a type is selected in the form.
      if (this.form.type.length > 0) {
        this.queryparams['feature_type_slug'] = this.form.type;
      }
      // Set 'status__value' if a status is selected in the form.
      if (this.form.status.length > 0) {
        this.queryparams['status__value'] = this.form.status;
      }
      // Set 'title' if a title is entered in the form.
      if (this.form.title) {
        this.queryparams['title'] = this.form.title;
      }
      // Update the 'ordering' parameter based on the current sorting state.
      // Prepends a '-' for descending order if sort.ascending is false.
      this.queryparams['ordering'] = `${this.sort.ascending ? '-' : ''}${this.getAvalaibleField(this.sort.column)}`;
    },

    /**
     * Fetches paginated feature data from the API.
     * This function is called to retrieve a specific page of features based on the current pagination settings and any applied filters.
     * If the application is offline, it displays a message and does not proceed with the API call.
     */
    fetchPagedFeatures() {
      // Check if the application is online; if not, display a message and return.
      if (!this.isOnline) {
        this.DISPLAY_MESSAGE({
          comment: 'Les signalements du projet non mis en cache ne sont pas accessibles en mode déconnecté',
        });
        return;
      }

      // Display a loading message.
      this.loading = true;

      // Update additional query parameters based on the current filter states.
      this.updateQueryParams();
      const queryString = new URLSearchParams(this.queryparams).toString();
      // Construct the base URL with query parameters.
      const url = `${this.API_BASE_URL}projects/${this.projectSlug}/feature-paginated/?limit=${this.pagination.pagesize}&${queryString}`;
      // Make an API call to get the paginated features.
      featureAPI.getPaginatedFeatures(url)
        .then((data) => {
          if (data) {
            // Update the component state with the data received from the API.
            this.featuresCount = data.count;
            this.featuresWithGeomCount = data.geom_count;
            this.previous = data.previous;
            this.next = data.next;
            this.paginatedFeatures = data.results;
          }
          // If there are features, update the bounding box.
          if (this.paginatedFeatures.length) {
            this.fetchBboxNfit(queryString);
          }
          // Trigger actions on filter change.
          this.onFilterChange();
          // Hide the loading message.
          this.loading = false;
        });
    },

    //* Pagination for table *//

    createPagesArray(featuresCount, pagesize) {
      const totalPages = Math.ceil(
        featuresCount / pagesize
      );
      return [...Array(totalPages).keys()].map((pageNumb) => {
        ++pageNumb;
        return pageNumb;
      });
    },

    handlePageChange(page) {
      if (page === 'next') {
        this.toNextPage();
      } else if (page === 'previous') {
        this.toPreviousPage();
      } else if (typeof page === 'number') {
        //* update limit and offset
        this.toPage(page);
      }
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.fetchPagedFeatures();
    },

    handleAllSelectedChange(isChecked) {
      this.allSelected = isChecked;
      // Si des sélections existent, tout déselectionner
      if (this.checkedFeatures.length > 0) {
        this.UPDATE_CHECKED_FEATURES([]);
      }
    },

    toPage(pageNumber) {
      const toAddOrRemove =
        (pageNumber - this.pagination.currentPage) * this.pagination.pagesize;
      this.pagination.start += toAddOrRemove;
      this.pagination.end += toAddOrRemove;
      this.pagination.currentPage = pageNumber;
      this.fetchPagedFeatures();
    },

    toPreviousPage() {
      if (this.pagination.currentPage !== 1) {
        if (this.pagination.start > 0) {
          this.pagination.start -= this.pagination.pagesize;
          this.pagination.end -= this.pagination.pagesize;
          this.pagination.currentPage -= 1;
        }
        this.fetchPagedFeatures();
      }
    },

    toNextPage() {
      if (this.pagination.currentPage !== this.pageNumbers.length) {
        if (this.pagination.end < this.featuresCount) {
          this.pagination.start += this.pagination.pagesize;
          this.pagination.end += this.pagination.pagesize;
          this.pagination.currentPage += 1;
        }
        this.fetchPagedFeatures();
      }
    },
  },
};
</script>


<style lang="less" scoped>
.loader-container {
  position: relative;
  min-height: 250px; // keep a the spinner above result and below table header
  z-index: 1;
  .ui.inverted.dimmer.active {
    opacity: .6;
  }
}
.map-container {
  width: 80vw;
  transform: translateX(-50%);
  margin-left: 50%;
  visibility: hidden;
  position: absolute;
  #map {
    min-height: 0;
  }
}
.map-container.visible {
  visibility: visible;
  position: relative;
  width: 100%;

  .sidebar-container {
    left: -250px;
  }

  .sidebar-container.expanded {
    left: 0;
  }

  #map {
    width: 100%;
    min-height: 310px;
    height: calc(100vh - 310px);
    border: 1px solid grey;
    /* To not hide the filters */
    z-index: 1;
  }
}
div.geolocation-container {
  // each button have (more or less depends on borders) .5em space between
  // zoom buttons are 60px high, geolocation and full screen button is 34px high with borders
  top: calc(1.3em + 60px + 34px);
}

@media screen and (max-width: 767px) {
  #project-features {
    margin: 1em auto 1em;
  }
  .map-container {
    width: 100%;
    position: relative;
  }
}

.fadeIn-enter-active {
  animation: fadeIn .5s;
}
.fadeIn-leave-active {
  animation: fadeIn .5s reverse;
}
.transition.fade.in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: .9
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: .9
  }
}

</style>

